<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"></loading>
            <h5 slot="header" class="mb-0">
              <b-button size="sm" :to="{ name: 'system.role.list' }" class="mr-2"><i class="fa fa-chevron-left"></i>
                Буцах</b-button>
              <span v-if="this.$route.params.id > 0">Засах: <strong class="text-primary">{{ form.name }}</strong></span>
              <span v-else>Шинээр нэмэх</span>
            </h5>

            <b-form-group id="input-group-name" label="Нэр:" label-for="input-name">
              <b-form-input id="input-name" v-model="form.code" type="text" required placeholder="Нэр"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-code" label="Код:" label-for="input-code">
              <b-form-input id="input-code" v-model="form.name" required placeholder="Харагдах нэр"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4">
              <b-form-checkbox value="true" v-model="form.isActive" switch>Идэвхтэй</b-form-checkbox>
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3">Шинэчлэх</b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>


export default {
  name: 'Role.edit',
  data: function () {
    return {
      isLoading: false,
      form: {
        name: '',
        code: '',
        isActive: false
      },
      show: true,
      roles: [],
      save_url: 'UserWebService/create_role'
    }
  },
  created: function () {
    this.checkSession();
    if (this.$route.params.id > 0) {
      this.$data.save_url = 'UserWebService/update_role'
      this.loadData()
    }
  },
  methods: {
    loadData: function () {
      this.isLoading = true;
      this.$http.post(this.$config.API_URL + 'UserWebService/get_role', {
        data: JSON.stringify({
          'token': this.$store.getters.token,
          'email': this.$store.getters.email,
          id: this.$route.params.id
        })
      },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true
        })
        .then(response => {
          this.isLoading = false;
          this.$data.form = response.body
        }, response => {
          this.isLoading = false;
          this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
        });
    },
    onSubmit(evt) {
      this.isLoading = true
      this.$data.form.token = this.$store.getters.token,
        this.$data.form.email = this.$store.getters.email

      this.$http.post(
        this.$config.API_URL + this.$data.save_url,
        { data: JSON.stringify(this.$data.form) },
        { headers: this.$store.getters.httpHeader, emulateJSON: true }
      ).then(
        response => {
          this.isLoading = false;
          this.showToast('Амжилттай', 'Амжилттай хадгалагдлаа', 'success')
          if (this.$route.params.id == 0) {
            this.onReset(evt)
          }
        },
        response => {
          this.isLoading = false;
          this.showToast('Алдаа', 'Алдаа гарсан байна', 'danger')
        }
      );
    },
    onReset(evt) {
      evt.preventDefault()
      this.$data.form = {
        name: '',
        code: '',
        icon: '',
        action: '',
        isActive: false,
        displayIndex: '',
        roles: ''
      }
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>